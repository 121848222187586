import { useEffect } from 'react';

const FontPreloader = () => {
  useEffect(() => {
    // Preload Inter font
    const interLink = document.createElement('link');
    interLink.rel = 'preload';
    interLink.as = 'style';
    interLink.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
    document.head.appendChild(interLink);

    // Preload Montserrat font
    const montserratLink = document.createElement('link');
    montserratLink.rel = 'preload';
    montserratLink.as = 'style';
    montserratLink.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800;900&display=swap';
    document.head.appendChild(montserratLink);

    // Actually load the fonts
    const interStyle = document.createElement('link');
    interStyle.rel = 'stylesheet';
    interStyle.href = interLink.href;
    document.head.appendChild(interStyle);

    const montserratStyle = document.createElement('link');
    montserratStyle.rel = 'stylesheet';
    montserratStyle.href = montserratLink.href;
    document.head.appendChild(montserratStyle);

    return () => {
      document.head.removeChild(interLink);
      document.head.removeChild(montserratLink);
      document.head.removeChild(interStyle);
      document.head.removeChild(montserratStyle);
    };
  }, []);

  return null;
};

export default FontPreloader; 