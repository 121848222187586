import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from './ui/button';
import { X, ChevronUp } from 'lucide-react';

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showNavButton, setShowNavButton] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Debug log to verify component is mounting
  useEffect(() => {
    console.log('Navbar component mounted');
  }, []);

  // Handle scroll events to show/hide nav button and back-to-top button
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercentage = (currentScrollY / (scrollHeight - clientHeight)) * 100;
      
      // Show nav button when scrolling up and we're not at the top
      if (currentScrollY < lastScrollY && currentScrollY > 100) {
        setShowNavButton(true);
      } else if (currentScrollY > lastScrollY || currentScrollY < 50) {
        // Hide nav button when scrolling down or at the top
        setShowNavButton(false);
      }
      
      // Show back-to-top button when near bottom (70% scrolled) or when scrolling stops
      if (scrollPercentage > 70) {
        setShowBackToTop(true);
      } else {
        // When not near bottom, only show after scrolling stops
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
        
        scrollTimeoutRef.current = setTimeout(() => {
          // Only show if we've scrolled down a bit
          if (currentScrollY > 300) {
            setShowBackToTop(true);
          }
        }, 1000); // Show after 1 second of no scrolling
      }
      
      setLastScrollY(currentScrollY);
    };
    
    // Hide back-to-top when scrolling starts
    const handleScrollStart = () => {
      // Only hide if not near bottom
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercentage = (window.scrollY / (scrollHeight - clientHeight)) * 100;
      
      if (scrollPercentage <= 70) {
        setShowBackToTop(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScrollStart, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScrollStart);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [lastScrollY]);

  const handleBookNowClick = () => {
    // Close menu when Book Now is clicked
    setIsVisible(false);

    const contactSection = document.getElementById('contact');
    if (!contactSection) return;

    // Scroll to contact section with offset for fixed header
    const navHeight = 80; // Approximate navbar height
    const contactPosition = contactSection.offsetTop - navHeight;
    window.scrollTo({
      top: contactPosition,
      behavior: 'smooth'
    });

    // Set up intersection observer to wait for scroll to complete
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // Once scrolled into view, handle the category selection
        const categorySelect = document.getElementById('category-select');
        if (categorySelect) {
          categorySelect.click();
          
          // Use MutationObserver to wait for the options to be rendered
          const optionsObserver = new MutationObserver((_mutations, obs) => {
            const bookingOption = document.querySelector('[data-value="booking"]');
            if (bookingOption) {
              (bookingOption as HTMLElement).click();
              obs.disconnect();
            }
          });

          optionsObserver.observe(document.body, {
            childList: true,
            subtree: true
          });

          // Cleanup observer after 1 second if nothing happens
          setTimeout(() => optionsObserver.disconnect(), 1000);
        }
        observer.disconnect();
      }
    }, { threshold: 0.5 });

    observer.observe(contactSection);
  };

  return (
    <>
      {/* Fixed Menu Button - only shows when scrolling up */}
      <AnimatePresence>
        {showNavButton && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="fixed top-6 right-6 z-[9999]"
          >
            <Button
              onClick={() => setIsVisible(!isVisible)}
              size="icon"
              className="bg-[#ea384c] hover:bg-[#ea384c]/90 text-white rounded-full h-14 w-14 shadow-lg flex items-center justify-center"
              aria-label={isVisible ? "Close menu" : "Open menu"}
            >
              {isVisible ? (
                <X className="h-7 w-7" />
              ) : (
                <img 
                  src="/SM-logo-only-white.svg" 
                  alt="Menu" 
                  className="h-8 w-8"
                />
              )}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Back to Top Button - positioned above the media player */}
      <AnimatePresence>
        {showBackToTop && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
            className="fixed bottom-24 right-6 z-[9999]"
          >
            <Button
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setShowBackToTop(false);
              }}
              size="icon"
              className="bg-black/80 hover:bg-black text-white rounded-full h-14 w-14 shadow-lg border border-white/20"
              aria-label="Back to top"
            >
              <ChevronUp className="h-7 w-7" />
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Full Navigation Menu */}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 z-[9998] bg-black/70 backdrop-blur-md flex items-center justify-center"
            onClick={() => setIsVisible(false)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="bg-black/90 rounded-2xl border border-white/20 p-8 max-w-md w-full mx-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-6">
                <a href="/" className="text-xl text-white">
                  <img 
                    src="/SM-logo-only-white.svg" 
                    alt="Serious Matters Logo" 
                    className="h-10"
                  />
                </a>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsVisible(false)}
                  className="text-white hover:bg-white/10 rounded-full"
                  aria-label="Close menu"
                >
                  <X className="h-6 w-6" />
                </Button>
              </div>

              <nav className="space-y-4">
                {[
                  { href: '#shows', label: 'Shows' },
                  { href: '#merch', label: 'Merch' },
                  { href: '#about', label: 'About' }
                ].map(({ href, label }) => (
                  <NavLink 
                    key={href} 
                    href={href}
                    onClick={() => setIsVisible(false)}
                  >
                    {label}
                  </NavLink>
                ))}
                <div className="pt-4">
                  <Button 
                    className="w-full bg-[#ea384c] hover:bg-[#ea384c]/90 text-white py-6"
                    onClick={handleBookNowClick}
                  >
                    Book Now
                  </Button>
                </div>
              </nav>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const NavLink = ({ href, children, onClick }: { href: string; children: React.ReactNode; onClick?: () => void }) => (
  <a
    href={href}
    className="block text-white text-xl font-medium py-3 px-4 rounded-lg hover:bg-white/10 transition-colors"
    onClick={(e) => {
      e.preventDefault();
      // Smooth scroll to the section
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      if (onClick) onClick();
    }}
  >
    {children}
  </a>
);

export default Navbar;
