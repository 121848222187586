import { MediaServiceProvider, MediaPlayerState, MediaTrack } from '../types/media';
import { 
  YouTubeAPI, 
  YouTubePlayer, 
  YouTubePlayerState
} from '../types/youtube';

declare global {
  interface Window {
    YT: YouTubeAPI;
    onYouTubeIframeAPIReady: () => void;
  }
}

interface YouTubeMessage {
  event: string;
  func: string;
  args?: unknown[];
}

export class YouTubeService implements MediaServiceProvider {
  private player: YouTubePlayer | null = null;
  public isInitialized: boolean = false;
  private containerId: string;
  private iframe: HTMLIFrameElement | null = null;
  private messageChannel: BroadcastChannel;
  private currentTime: number = 0;
  private duration: number = 0;
  private playerState: YouTubePlayerState = YouTubePlayerState.UNSTARTED;
  private timeUpdateInterval: NodeJS.Timeout | undefined;

  constructor(containerId: string) {
    this.containerId = containerId;
    this.messageChannel = new BroadcastChannel('youtube-player-state');
  }

  async initialize(): Promise<void> {
    if (this.isInitialized) {
      return;
    }

    return new Promise((resolve) => {
      try {
        // Create a hidden container for the YouTube player if it doesn't exist
        let container = document.getElementById(this.containerId);
        if (!container) {
          container = document.createElement('div');
          container.id = this.containerId;
          container.style.position = 'absolute';
          container.style.top = '-9999px';
          container.style.left = '-9999px';
          container.style.width = '1px';
          container.style.height = '1px';
          container.style.pointerEvents = 'none';
          document.body.appendChild(container);
        }
        
        // Create a script element for the YouTube IFrame API
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        
        // Add error handling for the script
        tag.onerror = () => {
          console.error('Failed to load YouTube IFrame API, resolving anyway');
          this.isInitialized = true; // Mark as initialized to prevent further attempts
          resolve(); // Resolve anyway to prevent app from breaking
        };
        
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

        // Add message event listener for iframe communication
        window.addEventListener('message', this.handleMessage);

        const onYouTubeIframeAPIReady = () => {
          try {
            this.player = new window.YT.Player(this.containerId, {
              height: '360',
              width: '640',
              videoId: '', // Start with no video
              playerVars: {
                autoplay: 0,
                controls: 0,
                modestbranding: 1,
                playsinline: 1,
                rel: 0,
                origin: window.location.origin,
                enablejsapi: 1,
                // Use standard YouTube domain
                host: 'https://www.youtube.com',
                // Mute by default to help with autoplay restrictions
                mute: 0
              },
              events: {
                onReady: () => {
                  console.log('YouTube player ready');
                  this.isInitialized = true;
                  // Store the iframe reference for postMessage communication
                  this.iframe = document.getElementById(this.containerId) as HTMLIFrameElement;
                  // Unmute the player
                  if (this.player) {
                    this.player.unMute();
                    this.player.setVolume(100);
                  }
                  resolve();
                },
                onStateChange: (event) => {
                  console.log('YouTube player state changed:', event.data);
                  this.playerState = event.data;
                  this.messageChannel.postMessage({ playerState: event.data });
                },
                onError: (event) => {
                  console.error('YouTube Player Error:', event.data);
                  // Don't reject on error, just log it
                  // This prevents the app from breaking if YouTube has issues
                  if (!this.isInitialized) {
                    this.isInitialized = true;
                    resolve();
                  }
                }
              }
            });
          } catch (error) {
            console.error('Error creating YouTube player:', error);
            this.isInitialized = true;
            resolve(); // Resolve anyway to prevent app from breaking
          }
        };

        // Set up the YouTube API callback
        if (window.YT && window.YT.Player) {
          onYouTubeIframeAPIReady();
        } else {
          window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        }
      } catch (error) {
        console.error('Error initializing YouTube player:', error);
        // Resolve anyway to prevent app from breaking
        this.isInitialized = true;
        resolve();
      }
    });
  }

  private handleMessage = (event: MessageEvent) => {
    if (!event.origin.endsWith('youtube-nocookie.com') && !event.origin.endsWith('youtube.com')) return;
    
    try {
      const data = JSON.parse(event.data);
      
      // Handle YouTube API events
      if (data.event === 'onReady') {
        // Player is ready, start requesting updates
        this.startTimeUpdates();
      } else if (data.event === 'onStateChange') {
        this.playerState = data.info;
        this.messageChannel.postMessage({ playerState: data.info });
      } else if (data.event === 'onPlaybackQualityChange') {
        // Handle quality change if needed
      } else if (data.event === 'onError') {
        console.error('YouTube Player Error:', data.info);
      } else if (data.info && typeof data.info === 'object') {
        // Handle time and duration updates
        if (data.info.currentTime !== undefined) {
          this.currentTime = data.info.currentTime;
        }
        if (data.info.duration !== undefined) {
          this.duration = data.info.duration;
        }
        // Broadcast updates
        this.messageChannel.postMessage(data.info);
      }
    } catch (error) {
      console.error('Error handling YouTube message:', error);
    }
  };

  private startTimeUpdates() {
    // Clear any existing interval
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
    
    // Request time updates every second
    this.timeUpdateInterval = setInterval(() => {
      this.postMessage({
        event: 'command',
        func: 'getPlayerState',
      });
      this.postMessage({
        event: 'command',
        func: 'getCurrentTime',
      });
      this.postMessage({
        event: 'command',
        func: 'getDuration',
      });
    }, 1000);
  }

  private postMessage(message: YouTubeMessage) {
    if (this.iframe?.contentWindow) {
      try {
        this.iframe.contentWindow.postMessage(JSON.stringify({
          event: 'command',
          func: message.func,
          args: message.args || [],
        }), '*');
      } catch (error) {
        console.error('Error posting message to YouTube player:', error);
      }
    }
  }

  async play(track: MediaTrack): Promise<void> {
    if (!this.player || !this.isInitialized) {
      await this.initialize();
    }

    return new Promise((resolve, reject) => {
      try {
        if (!this.player) {
          reject(new Error('Player not initialized'));
          return;
        }

        console.log('Playing track:', track.title, 'with ID:', track.sourceId);
        
        // Make sure the player is unmuted
        this.player.unMute();
        this.player.setVolume(100);
        
        // Load and play the video
        this.player.loadVideoById(track.sourceId);
        
        // Explicitly call playVideo after a short delay to ensure it plays
        setTimeout(() => {
          if (this.player) {
            this.player.playVideo();
          }
        }, 100);
        
        resolve();
      } catch (error) {
        console.error('Error playing track:', error);
        reject(error);
      }
    });
  }

  async loadTrack(track: MediaTrack): Promise<void> {
    if (!this.player || !this.isInitialized) {
      await this.initialize();
    }

    return new Promise((resolve, reject) => {
      try {
        if (!this.player) {
          reject(new Error('Player not initialized'));
          return;
        }

        this.player.cueVideoById(track.sourceId);
        resolve();
      } catch (error) {
        console.error('Error loading track:', error);
        reject(error);
      }
    });
  }

  async pause(): Promise<void> {
    if (!this.player) return;
    try {
      this.player.pauseVideo();
    } catch (error) {
      console.error('Error pausing video:', error);
    }
  }

  async seek(time: number): Promise<void> {
    if (!this.player) return;
    this.player.seekTo(time, true);
  }

  async setVolume(volume: number): Promise<void> {
    if (!this.player) return;
    this.player.setVolume(volume * 100);
  }

  async getCurrentState(): Promise<MediaPlayerState> {
    if (!this.player) {
      return {
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        volume: 0,
        muted: false,
      };
    }

    return {
      isPlaying: this.playerState === YouTubePlayerState.PLAYING,
      currentTime: this.currentTime,
      duration: this.duration,
      volume: this.player.getVolume() / 100,
      muted: this.player.isMuted(),
    };
  }

  async destroy(): Promise<void> {
    if (!this.player) return;
    
    // Clear the time update interval
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
      this.timeUpdateInterval = undefined;
    }
    
    // Remove message event listener
    window.removeEventListener('message', this.handleMessage);
    
    this.player.destroy();
    this.isInitialized = false;
    this.player = null;
  }
} 