import { Outlet } from 'react-router-dom';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import MediaPlaylist from '@/components/MediaPlaylist';

const Layout = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <Navbar />
      <Outlet />
      <Footer />
      <MediaPlaylist />
    </div>
  );
};

export default Layout; 