import { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MediaTrack } from '@/lib/types/media';
import { YouTubePlayerState } from '@/lib/types/youtube';
import { 
  Play, 
  Pause, 
  SkipBack, 
  SkipForward, 
  ListMusic,
  Loader2,
} from 'lucide-react';
import { Button } from './ui/button';
import { seriousMattersPlaylist } from '@/data/songs';
import { cn } from '@/lib/utils';
import { YouTubeService } from '@/lib/services/YouTubeService';

// Create a single instance of YouTubeService
const youtubePlayer = new YouTubeService('youtube-player');

// Sound visualization component
const SoundVisualization = ({ isPlaying }: { isPlaying: boolean }) => {
  // Generate random heights for the bars
  const bars = Array.from({ length: 12 }, () => Math.random() * 100);
  
  return (
    <div className="flex items-end h-8 gap-[2px] mx-4">
      {bars.map((height, index) => (
        <motion.div
          key={index}
          initial={{ height: '10%' }}
          animate={{ 
            height: isPlaying ? `${20 + height * 0.8}%` : '10%',
            transition: { 
              duration: 0.4,
              repeat: isPlaying ? Infinity : 0,
              repeatType: 'reverse',
              delay: index * 0.05,
            }
          }}
          className="w-1 bg-gradient-to-t from-[#ea384c] to-[#ff8a9d] rounded-t-sm"
        />
      ))}
    </div>
  );
};

const MediaPlaylist = () => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const currentTrack = seriousMattersPlaylist[currentTrackIndex];

  // Initialize YouTube player
  useEffect(() => {
    const initPlayer = async () => {
      try {
        setIsLoading(true);
        await youtubePlayer.initialize();
        // Load the first track but don't play it
        if (currentTrack) {
          await youtubePlayer.loadTrack(currentTrack);
        }
      } catch (error) {
        console.error('Failed to initialize YouTube player:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initPlayer();

    // Listen for YouTube player state changes
    const channel = new BroadcastChannel('youtube-player-state');
    channel.onmessage = (event) => {
      const state = event.data;
      if (state.playerState !== undefined) {
        setIsPlaying(state.playerState === YouTubePlayerState.PLAYING);
        setIsLoading(state.playerState === YouTubePlayerState.BUFFERING);
      }
    };

    // Add global error handler for CORS errors
    const handleError = (event: ErrorEvent) => {
      try {
        // Safely check if it's a CORS error related to YouTube
        const message = event.message || '';
        const filename = event.filename || '';
        
        if ((message.indexOf('CORS') !== -1) && 
            (message.indexOf('youtube') !== -1 || filename.indexOf('youtube') !== -1)) {
          // Prevent the error from showing in the console
          event.preventDefault();
          // Don't propagate the error
          event.stopPropagation();
          return true;
        }
      } catch (err) {
        // If anything goes wrong in our error handler, just ignore it
        // This prevents cascading errors
        console.log('Error in error handler, ignoring:', err);
      }
      return false;
    };

    // Add the error handler
    window.addEventListener('error', handleError, true);

    return () => {
      channel.close();
      youtubePlayer.destroy();
      // Remove the error handler
      window.removeEventListener('error', handleError, true);
    };
  }, [currentTrack]);

  const handlePlay = useCallback(async (track: MediaTrack) => {
    try {
      setIsLoading(true);
      await youtubePlayer.play(track);
    } catch (error) {
      console.error('Failed to play track:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const togglePlayPause = useCallback(async () => {
    try {
      setIsLoading(true);
      if (isPlaying) {
        await youtubePlayer.pause();
      } else {
        // For resuming or initial play
        await youtubePlayer.play(currentTrack);
      }
    } catch (error) {
      console.error('Failed to toggle play/pause:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isPlaying, currentTrack]);

  const handlePrevious = useCallback(async () => {
    if (currentTrackIndex > 0) {
      const newIndex = currentTrackIndex - 1;
      setCurrentTrackIndex(newIndex);
      if (isPlaying) {
        await handlePlay(seriousMattersPlaylist[newIndex]);
      }
    }
  }, [currentTrackIndex, isPlaying, handlePlay]);

  const handleNext = useCallback(async () => {
    if (currentTrackIndex < seriousMattersPlaylist.length - 1) {
      const newIndex = currentTrackIndex + 1;
      setCurrentTrackIndex(newIndex);
      if (isPlaying) {
        await handlePlay(seriousMattersPlaylist[newIndex]);
      }
    }
  }, [currentTrackIndex, isPlaying, handlePlay]);

  const handleTrackSelect = useCallback(async (index: number) => {
    setCurrentTrackIndex(index);
    await handlePlay(seriousMattersPlaylist[index]);
  }, [handlePlay]);

  return (
    <div className="fixed bottom-0 left-0 right-0 z-40 bg-black/95 border-t border-[#ea384c]/20 opacity-100 transition-opacity duration-300">
      <div className="container mx-auto max-w-3xl px-3 py-3 md:p-4">
        <div className="flex items-center gap-3 md:gap-4">
          <img
            src={currentTrack.thumbnail}
            alt={`${currentTrack.title} Album Art`}
            className="w-14 h-14 md:w-16 md:h-16 rounded-md object-cover"
          />
          
          <div className="flex-1 min-w-0">
            <h3 className="text-white font-medium text-sm md:text-base truncate">{currentTrack.title}</h3>
            <p className="text-white/70 text-xs md:text-sm truncate">{currentTrack.artist}</p>
          </div>

          <div className="flex items-center gap-1 md:gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={handlePrevious}
              disabled={currentTrackIndex === 0 || isLoading}
              className="text-white hover:bg-black/40 h-8 w-8 md:h-10 md:w-10"
              aria-label="Previous track"
            >
              <SkipBack className="h-4 w-4 md:h-5 md:w-5" />
            </Button>

            <Button
              variant="ghost"
              size="icon"
              onClick={togglePlayPause}
              disabled={isLoading}
              className="bg-[#ea384c] hover:bg-[#ea384c]/80 text-white rounded-full transition-colors h-12 w-12 md:h-14 md:w-14 flex items-center justify-center"
              aria-label={isPlaying ? "Pause" : "Play"}
            >
              {isLoading ? (
                <Loader2 className="h-5 w-5 md:h-6 md:w-6 animate-spin" />
              ) : isPlaying ? (
                <Pause className="h-5 w-5 md:h-6 md:w-6" />
              ) : (
                <Play className="h-5 w-5 md:h-6 md:w-6 ml-0.5" />
              )}
            </Button>

            <Button
              variant="ghost"
              size="icon"
              onClick={handleNext}
              disabled={currentTrackIndex === seriousMattersPlaylist.length - 1 || isLoading}
              className="text-white hover:bg-black/40 h-8 w-8 md:h-10 md:w-10"
              aria-label="Next track"
            >
              <SkipForward className="h-4 w-4 md:h-5 md:w-5" />
            </Button>
            
            {/* Sound visualization - hidden on mobile */}
            <div className="hidden md:block">
              <SoundVisualization isPlaying={isPlaying} />
            </div>

            <Button
              variant="ghost"
              size="icon"
              onClick={() => setShowPlaylist(!showPlaylist)}
              className="text-white hover:bg-black/40 h-8 w-8 md:h-10 md:w-10"
              aria-label={showPlaylist ? "Hide playlist" : "Show playlist"}
            >
              <ListMusic className="h-4 w-4 md:h-5 md:w-5" />
            </Button>
          </div>
        </div>

        <AnimatePresence>
          {showPlaylist && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mt-4 space-y-2 max-h-[40vh] overflow-y-auto"
            >
              {seriousMattersPlaylist.map((track, index) => (
                <button
                  key={track.id}
                  onClick={() => handleTrackSelect(index)}
                  disabled={isLoading}
                  className={cn(
                    "w-full p-2 rounded flex items-center gap-3",
                    "hover:bg-black/40",
                    currentTrackIndex === index && "bg-black/40"
                  )}
                >
                  <img
                    src={track.thumbnail}
                    alt={track.title}
                    className="w-10 h-10 rounded"
                  />
                  <div className="flex-1 text-left">
                    <div className="text-white truncate">{track.title}</div>
                    <div className="text-white/70 text-sm truncate">{track.artist}</div>
                  </div>
                  {currentTrackIndex === index && isPlaying && (
                    <div className="flex items-center gap-[2px]">
                      {[1, 2, 3].map((_, i) => (
                        <motion.div
                          key={i}
                          animate={{
                            height: ['40%', '100%', '40%'],
                            transition: {
                              duration: 1,
                              repeat: Infinity,
                              delay: i * 0.2,
                            }
                          }}
                          className="w-1 h-3 bg-[#ea384c] rounded-full"
                        />
                      ))}
                    </div>
                  )}
                </button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Hidden YouTube Player */}
      <div className="fixed -left-[9999px] -top-[9999px] w-0 h-0 overflow-hidden">
        <div id="youtube-player" />
      </div>
    </div>
  );
};

export default MediaPlaylist; 