import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { useEffect, lazy, Suspense } from 'react';
import Layout from "@/components/Layout";
import FontPreloader from "@/components/FontPreloader";
import "@/styles/components.css";

// Lazy load pages for code splitting
const Index = lazy(() => import("./pages/Index"));
const NotFound = lazy(() => import("./pages/NotFound"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/Terms"));
const ServerError = lazy(() => import("./pages/ServerError"));
const ServiceUnavailable = lazy(() => import("./pages/ServiceUnavailable"));
const Maintenance = lazy(() => import("./pages/Maintenance"));

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false, // Prevent refetch on window focus
      retry: false, // Disable retries
    },
  },
});

// Loading fallback for lazy-loaded components
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#ea384c]"></div>
  </div>
);

function App() {
  // Register service worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        try {
          const registration = await navigator.serviceWorker.register('/sw.js', {
            scope: '/'
          });
          console.log('ServiceWorker registration successful:', registration.scope);
          
          // Handle updates
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    console.log('New content is available; please refresh.');
                    // You could show a notification to the user here
                  } else {
                    console.log('Content is cached for offline use.');
                  }
                }
              };
            }
          };
        } catch (error) {
          console.error('ServiceWorker registration failed:', error);
        }
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <TooltipProvider>
          <Helmet>
            {/* Preconnect to required origins */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            
            {/* Preload critical assets */}
            <link rel="preload" href="/optimized/team/SM.webp" as="image" />
            
            {/* Meta tags for performance */}
            <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
            {/* Use color-scheme meta tag for cross-browser support */}
            <meta name="color-scheme" content="dark light" />
            
            {/* Open Graph Meta Tags */}
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Serious Matters" />
            <meta property="og:title" content="Serious Matters" />
            <meta property="og:description" content="New Jersey's Post-Hardcore powerhouse, bringing you raw emotion and powerful performances." />
            <meta property="og:image" content="/optimized/team/SM.webp" />
            
            {/* Schema.org data */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "MusicGroup",
                "name": "Serious Matters",
                "description": "New Jersey's Post-Hardcore powerhouse, bringing you raw emotion and powerful performances.",
                "image": "/optimized/team/SM.webp",
                "genre": ["Post-Hardcore", "Rock"],
                "foundingLocation": {
                  "@type": "Place",
                  "name": "New Jersey",
                  "address": {
                    "@type": "PostalAddress",
                    "addressRegion": "NJ",
                    "addressCountry": "US"
                  }
                },
                "sameAs": [
                  "https://instagram.com/seriousmattersnj",
                  "https://facebook.com/seriousmattersnj",
                  "https://youtube.com/@seriousmatters"
                ]
              })}
            </script>
          </Helmet>
          <FontPreloader />
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Suspense fallback={<LoadingFallback />}><Index /></Suspense>} />
              <Route path="/privacy" element={<Suspense fallback={<LoadingFallback />}><PrivacyPolicy /></Suspense>} />
              <Route path="/terms" element={<Suspense fallback={<LoadingFallback />}><Terms /></Suspense>} />
              <Route path="/500" element={<Suspense fallback={<LoadingFallback />}><ServerError /></Suspense>} />
              <Route path="/503" element={<Suspense fallback={<LoadingFallback />}><ServiceUnavailable /></Suspense>} />
              <Route path="/maintenance" element={<Suspense fallback={<LoadingFallback />}><Maintenance /></Suspense>} />
              <Route path="*" element={<Suspense fallback={<LoadingFallback />}><NotFound /></Suspense>} />
            </Route>
          </Routes>
          <Toaster />
          <Sonner />
        </TooltipProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
