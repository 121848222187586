import { MediaTrack } from '@/lib/types/media';

const whereeverWheneverArt = '/lovable-uploads/wherever-whenever.png';
const mistakingWaspsArt = '/lovable-uploads/mistaking-wasps-for-dragonflies-cd.png';
const throughItAllArt = '/lovable-uploads/through-it-all-cd.png';

export const seriousMattersPlaylist: MediaTrack[] = [
  {
    id: 'sm1',
    title: 'Wherever, Whenever',
    artist: 'Serious Matters',
    sourceType: 'youtube',
    sourceId: '5QtSBzrHMFQ',
    thumbnail: whereeverWheneverArt,
    duration: 240 // 4:00 (approximate, will be updated by YouTube)
  },
  {
    id: 'sm2',
    title: 'Waves',
    artist: 'Serious Matters',
    sourceType: 'youtube',
    sourceId: 'PGX3I0puUJg',
    thumbnail: throughItAllArt,
    duration: 210 // 3:30 (approximate, will be updated by YouTube)
  },
  {
    id: 'sm3',
    title: 'Patterns',
    artist: 'Serious Matters',
    sourceType: 'youtube',
    sourceId: 'gaHPCHfCMKY',
    thumbnail: throughItAllArt,
    duration: 225 // 3:45 (approximate, will be updated by YouTube)
  },
  {
    id: 'sm4',
    title: 'Chasing Sunlight',
    artist: 'Serious Matters',
    sourceType: 'youtube',
    sourceId: 'b_f7Nw7WiUw',
    thumbnail: mistakingWaspsArt,
    duration: 230 // 3:50 (approximate, will be updated by YouTube)
  },
  {
    id: 'sm5',
    title: 'Glitches',
    artist: 'Serious Matters',
    sourceType: 'youtube',
    sourceId: 'rkmtfa-Qs1k',
    thumbnail: mistakingWaspsArt,
    duration: 220 // 3:40 (approximate, will be updated by YouTube)
  },
  {
    id: 'sm6',
    title: 'The Stars Align',
    artist: 'Serious Matters',
    sourceType: 'youtube',
    sourceId: 'uVNMsd9Shyo',
    thumbnail: mistakingWaspsArt,
    duration: 235 // 3:55 (approximate, will be updated by YouTube)
  }
]; 