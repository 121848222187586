// YouTube Player States
export enum YouTubePlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5
}

// YouTube Player Error States
export enum YouTubePlayerError {
  INVALID_PARAM = 2,
  HTML5_ERROR = 5,
  NOT_FOUND = 100,
  NOT_ALLOWED = 101,
  NOT_ALLOWED_EMBED = 150
}

// YouTube Player Parameters
export interface YouTubePlayerVars {
  autoplay?: 0 | 1;
  controls?: 0 | 1;
  modestbranding?: 1;
  playsinline?: 1;
  rel?: 0 | 1;
  origin?: string;
  enablejsapi?: 1;
  host?: string;
  privacy_mode?: boolean;
  cookie?: boolean;
  nocookie?: boolean;
  // Additional parameters for CORS handling
  widget_referrer?: string;
  crossorigin?: string;
  // Allow additional string parameters that YouTube might support
  [key: string]: string | number | boolean | undefined;
}

// YouTube Video Data
export interface YouTubeVideoData {
  video_id: string;
  author: string;
  title: string;
}

// YouTube Event Data
export interface YouTubeEventData {
  target: YouTubePlayer;
  data: YouTubePlayerState | YouTubePlayerError;
}

// YouTube Player Events
export interface YouTubePlayerEvents {
  onReady?: (event: { target: YouTubePlayer }) => void;
  onStateChange?: (event: { target: YouTubePlayer; data: YouTubePlayerState }) => void;
  onError?: (event: { target: YouTubePlayer; data: YouTubePlayerError }) => void;
  onPlaybackQualityChange?: (event: { target: YouTubePlayer; data: string }) => void;
  onPlaybackRateChange?: (event: { target: YouTubePlayer; data: number }) => void;
}

// YouTube Player Options
export interface YouTubePlayerOptions {
  videoId?: string;
  width?: string | number;
  height?: string | number;
  playerVars?: YouTubePlayerVars;
  events?: {
    onReady?: (event: { target: YouTubePlayer }) => void;
    onStateChange?: (event: { target: YouTubePlayer; data: YouTubePlayerState }) => void;
    onError?: (event: { target: YouTubePlayer; data: number }) => void;
  };
}

// YouTube Player Methods
export interface YouTubePlayer {
  loadVideoById: (videoId: string) => void;
  cueVideoById: (videoId: string) => void;
  playVideo: () => void;
  pauseVideo: () => void;
  stopVideo: () => void;
  seekTo: (seconds: number, allowSeekAhead: boolean) => void;
  getPlayerState: () => YouTubePlayerState;
  getCurrentTime: () => number;
  getDuration: () => number;
  getVolume: () => number;
  setVolume: (volume: number) => void;
  mute: () => void;
  unMute: () => void;
  isMuted: () => boolean;
  destroy: () => void;
  getVideoData(): YouTubeVideoData;
  addEventListener(event: string, listener: (event: YouTubeEventData) => void): void;
  removeEventListener(event: string, listener: (event: YouTubeEventData) => void): void;
}

// YouTube API
export interface YouTubeAPI {
  Player: {
    new (elementId: string | HTMLElement, options: YouTubePlayerOptions): YouTubePlayer;
  };
} 