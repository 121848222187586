import { Link } from 'react-router-dom';
import { Instagram, Facebook, Youtube } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const socialLinks = {
    instagram: "https://instagram.com/seriousmattersnj",
    facebook: "https://facebook.com/seriousmattersnj",
    youtube: "https://youtube.com/@seriousmatters",
  };
  
  return (
    <footer className="bg-black py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo and About */}
          <div className="col-span-1 md:col-span-2">
            <Link to="/">
              <img 
                src="/SM-logo-only-white.svg"
                alt="Serious Matters Logo"
                className="h-12 mb-4"
              />
            </Link>
            <p className="text-gray-300 mb-4">
              New Jersey&apos;s Post-Hardcore powerhouse, bringing you raw emotion and powerful performances.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {[
                { href: '/#shows', label: 'Shows' },
                { href: '/#merch', label: 'Merch' },
                { href: '/#about', label: 'About' }
              ].map(({ href, label }) => (
                <li key={href}>
                  <a 
                    href={href} 
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    {label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Social Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">Connect</h3>
            <div className="flex space-x-4 mb-6">
              {[
                { Icon: Instagram, href: socialLinks.instagram },
                { Icon: Facebook, href: socialLinks.facebook },
                { Icon: Youtube, href: socialLinks.youtube },
              ].map(({ Icon, href }) => (
                <a 
                  key={href}
                  href={href} 
                  className="text-gray-300 hover:text-white transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Visit our ${href.split('.com/')[1]} page`}
                >
                  <Icon className="h-6 w-6" />
                </a>
              ))}
            </div>
            <div className="space-y-2">
              {[
                { to: '/privacy', label: 'Privacy Policy' },
                { to: '/terms', label: 'Terms of Use' }
              ].map(({ to, label }) => (
                <Link 
                  key={to}
                  to={to} 
                  className="block text-gray-300 hover:text-white transition-colors text-sm"
                >
                  {label}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8 pb-24 md:pb-28">
          <div className="flex items-center justify-center gap-1 text-gray-300 flex-wrap">
            <p>© {currentYear} Serious Matters. All rights reserved.</p>
            <span className="mx-2">•</span>
            <p>
              Made with <span className="mx-0.5">♪</span> by{' '}
              <a 
                href="https://jukeboxjay.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-[#ea384c] hover:text-[#ea384c]/80 transition-colors font-medium"
              >
                Jukeboxjay
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 